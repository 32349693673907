;(function () {
	'use strict'
	var CookieConsent = function (root) {
		var ENABLE_LOGS = false
		var _config = {
			current_lang: 'en',
			auto_language: null,
			autorun: true,
			cookie_name: 'cc_cookie',
			cookie_expiration: 182,
			cookie_domain: window.location.hostname,
			cookie_path: '/',
			cookie_same_site: 'Lax',
			use_rfc_cookie: false,
			autoclear_cookies: true,
			revision: 0,
			script_selector: 'data-cookiecategory',
		}
		var _cookieconsent = {}
		var saved_cookie_content = {}
		var consent_modal_exists = false
		var cookie_consent_accepted = false
		var consent_modal_visible = false
		var settings_modal_visible = false
		var clicked_inside_modal = false
		var current_modal_focusable
		var all_table_headers, all_blocks, onAccept, onChange, onFirstAction
		var valid_revision = true,
			revision_enabled = false,
			data = null
		var accept_type
		var accepted_categories = []
		var rejected_categories = []
		var is_bot = false
		var last_elem_before_modal
		var last_consent_modal_btn_focus
		var consent_modal_focusable = []
		var settings_modal_focusable = []
		var toggle_states = []
		var toggle_categories = []
		var toggle_readonly = []
		var html_dom = document.documentElement
		var main_container
		var consent_modal
		var settings_container, settings_inner
		var _setConfig = function (conf_params) {
			_log('CookieConsent [CONFIG]: received_config_settings ', conf_params)
			if (typeof conf_params['cookie_expiration'] === 'number') {
				_config.cookie_expiration = conf_params['cookie_expiration']
			}
			if (typeof conf_params['cookie_necessary_only_expiration'] === 'number') {
				_config.cookie_necessary_only_expiration = conf_params['cookie_necessary_only_expiration']
			}
			if (typeof conf_params['autorun'] === 'boolean') {
				_config.autorun = conf_params['autorun']
			}
			if (typeof conf_params['cookie_domain'] === 'string') {
				_config.cookie_domain = conf_params['cookie_domain']
			}
			if (typeof conf_params['cookie_same_site'] === 'string') {
				_config.cookie_same_site = conf_params['cookie_same_site']
			}
			if (typeof conf_params['cookie_path'] === 'string') {
				_config.cookie_path = conf_params['cookie_path']
			}
			if (typeof conf_params['cookie_name'] === 'string') {
				_config.cookie_name = conf_params['cookie_name']
			}
			if (typeof conf_params['onAccept'] === 'function') {
				onAccept = conf_params['onAccept']
			}
			if (typeof conf_params['onFirstAction'] === 'function') {
				onFirstAction = conf_params['onFirstAction']
			}
			if (typeof conf_params['onChange'] === 'function') {
				onChange = conf_params['onChange']
			}
			if (typeof conf_params['revision'] === 'number') {
				conf_params['revision'] > -1 && (_config.revision = conf_params['revision'])
				revision_enabled = true
			}
			if (conf_params['autoclear_cookies'] === true) {
				_config.autoclear_cookies = true
			}
			if (conf_params['use_rfc_cookie'] === true) {
				_config.use_rfc_cookie = true
			}
			if (conf_params['hide_from_bots'] === true) {
				is_bot =
					navigator &&
					((navigator.userAgent && /bot|crawl|spider|slurp|teoma/i.test(navigator.userAgent)) ||
						navigator.webdriver)
			}
			_config.page_scripts = conf_params['page_scripts'] === true
			_config.page_scripts_order = conf_params['page_scripts_order'] !== false
			if (conf_params['auto_language'] === 'browser' || conf_params['auto_language'] === true) {
				_config.auto_language = 'browser'
			} else if (conf_params['auto_language'] === 'document') {
				_config.auto_language = 'document'
			}
			_config.current_lang = _resolveCurrentLang(conf_params.languages, conf_params['current_lang'])
		}
		var _addCookieSettingsButtonListener = function () {
			var all_links = document.querySelectorAll('a[data-cc="c-settings"], button[data-cc="c-settings"]')
			for (var x = 0; x < all_links.length; x += 1) {
				all_links[x].setAttribute('aria-haspopup', 'dialog')
				_addEvent(all_links[x], 'click', function (event) {
					_cookieconsent.showSettings(0)
					event.preventDefault ? event.preventDefault() : (event.returnValue = false)
				})
			}
		}
		var _getValidatedLanguage = function (lang, all_languages) {
			if (Object.prototype.hasOwnProperty.call(all_languages, lang)) {
				return lang
			} else if (_getKeys(all_languages).length > 0) {
				if (Object.prototype.hasOwnProperty.call(all_languages, _config.current_lang)) {
					return _config.current_lang
				} else {
					return _getKeys(all_languages)[0]
				}
			}
		}
		var _getModalFocusableData = function () {
			var allowed_focusable_types = ['[href]', 'button', 'input', 'details', '[tabindex="0"]']
			function _getAllFocusableElements(modal, _array) {
				var focus_later = false,
					focus_first = false
				try {
					var focusable_elems = modal.querySelectorAll(
						allowed_focusable_types.join(':not([tabindex="-1"]), ')
					)
					var attr,
						len = focusable_elems.length,
						i = 0
					while (i < len) {
						attr = focusable_elems[i].getAttribute('data-focus')
						if (!focus_first && attr === '1') {
							focus_first = focusable_elems[i]
						} else if (attr === '0') {
							focus_later = focusable_elems[i]
							if (!focus_first && focusable_elems[i + 1].getAttribute('data-focus') !== '0') {
								focus_first = focusable_elems[i + 1]
							}
						}
						i += 1
					}
				} catch (e) {
					return modal.querySelectorAll(allowed_focusable_types.join(', '))
				}
				_array[0] = focusable_elems[0]
				_array[1] = focusable_elems[focusable_elems.length - 1]
				_array[2] = focus_later
				_array[3] = focus_first
			}
			_getAllFocusableElements(settings_inner, settings_modal_focusable)
			if (consent_modal_exists) {
				_getAllFocusableElements(consent_modal, consent_modal_focusable)
			}
		}
		var _conf_params,
			_createConsentModal,
			revision_message = '',
			consent_text
		var _createCookieConsentHTML = function (never_accepted, conf_params) {
			main_container = _createNode('div')
			main_container.id = 'cc--main'
			main_container.style.position = 'fixed'
			main_container.style.zIndex = '1000000'
			main_container.innerHTML =
				'<!--[if lt IE 9 ]><div id="cc_div" class="cc_div ie"></div><![endif]--><!--[if (gt IE 8)|!(IE)]><!--><div id="cc_div" class="cc_div theme-funky"></div><!--<![endif]-->'
			var all_modals_container = main_container.children[0]
			var lang = _config.current_lang
			var innerText = typeof html_dom.textContent === 'string' ? 'textContent' : 'innerText'
			_conf_params = conf_params
			_createConsentModal = function (conf_params) {
				if (conf_params['force_consent'] === true) {
					_addClass(html_dom, 'force--consent')
				}
				var description = conf_params.languages[lang]['consent_modal']['description']
				if (revision_enabled) {
					if (!valid_revision) {
						description = description.replace(
							'{{revision_message}}',
							revision_message || conf_params.languages[lang]['consent_modal']['revision_message'] || ''
						)
					} else {
						description = description.replace('{{revision_message}}', '')
					}
				}
				if (consent_modal) {
					consent_text.innerHTML = description
					return
				}
				consent_modal = _createNode('div')
				var consent_modal_inner = _createNode('div')
				var consent_modal_inner_inner = _createNode('div')
				consent_text = _createNode('div')
				var consent_buttons = _createNode('div')
				var overlay = _createNode('div')
				consent_modal.id = 'cm'
				consent_modal_inner.id = 'c-inr'
				consent_modal_inner_inner.id = 'c-inr-i'
				consent_text.id = 'c-txt'
				consent_buttons.id = 'c-bns'
				overlay.id = 'cm-ov'
				consent_modal.setAttribute('role', 'dialog')
				consent_modal.setAttribute('aria-modal', 'true')
				consent_modal.setAttribute('aria-hidden', 'false')
				consent_modal.setAttribute('aria-labelledby', 'c-ttl')
				consent_modal.setAttribute('aria-describedby', 'c-txt')
				consent_modal.style.visibility = overlay.style.visibility = 'hidden'
				overlay.style.opacity = 0
				var consent_modal_title_value = conf_params.languages[lang]['consent_modal']['title']
				if (consent_modal_title_value) {
					var consent_title = _createNode('div')
					consent_title.id = 'c-ttl'
					consent_title.setAttribute('role', 'heading')
					consent_title.setAttribute('aria-level', '2')
					consent_title.insertAdjacentHTML('beforeend', consent_modal_title_value)
					consent_modal_inner_inner.appendChild(consent_title)
				}
				consent_text.insertAdjacentHTML('beforeend', description)
				consent_modal_inner_inner.appendChild(consent_text)
				var primary_btn_data = conf_params.languages[lang]['consent_modal']['primary_btn'],
					secondary_btn_data = conf_params.languages[lang]['consent_modal']['secondary_btn']
				if (primary_btn_data) {
					var consent_primary_btn = _createNode('button')
					consent_primary_btn.id = 'c-p-bn'
					consent_primary_btn.className = 'c-bn'
					consent_primary_btn[innerText] = conf_params.languages[lang]['consent_modal']['primary_btn']['text']
					var _accept_type
					if (primary_btn_data['role'] === 'accept_all') {
						_accept_type = 'all'
					}
					_addEvent(consent_primary_btn, 'click', function () {
						_cookieconsent.hide()
						_log('CookieConsent [ACCEPT]: cookie_consent was accepted!')
						_cookieconsent.accept(_accept_type)
					})
				}
				if (secondary_btn_data) {
					var consent_secondary_btn = _createNode('button')
					consent_secondary_btn.id = 'c-s-bn'
					consent_secondary_btn.className = 'c-bn c_link'
					consent_secondary_btn[innerText] =
						conf_params.languages[lang]['consent_modal']['secondary_btn']['text']
					if (secondary_btn_data['role'] === 'accept_necessary') {
						_addEvent(consent_secondary_btn, 'click', function () {
							_cookieconsent.hide()
							_cookieconsent.accept([])
						})
					} else {
						_addEvent(consent_secondary_btn, 'click', function () {
							_cookieconsent.showSettings(0)
						})
					}
				}
				var gui_options_data = conf_params['gui_options']
				if (
					gui_options_data &&
					gui_options_data['consent_modal'] &&
					gui_options_data['consent_modal']['swap_buttons'] === true
				) {
					secondary_btn_data && consent_buttons.appendChild(consent_secondary_btn)
					primary_btn_data && consent_buttons.appendChild(consent_primary_btn)
					consent_buttons.className = 'swap'
				} else {
					primary_btn_data && consent_buttons.appendChild(consent_primary_btn)
					secondary_btn_data && consent_buttons.appendChild(consent_secondary_btn)
				}
				consent_modal_inner.appendChild(consent_modal_inner_inner)
				;(primary_btn_data || secondary_btn_data) && consent_modal_inner.appendChild(consent_buttons)
				consent_modal.appendChild(consent_modal_inner)
				all_modals_container.appendChild(consent_modal)
				all_modals_container.appendChild(overlay)
				consent_modal_exists = true
			}
			if (!never_accepted) {
				_createConsentModal(conf_params)
			}
			settings_container = _createNode('div')
			var settings_container_valign = _createNode('div')
			var settings = _createNode('div')
			var settings_container_inner = _createNode('div')
			settings_inner = _createNode('div')
			var settings_title = _createNode('div')
			var settings_header = _createNode('div')
			var settings_close_btn = _createNode('button')
			var settings_close_btn_container = _createNode('div')
			var settings_blocks = _createNode('div')
			var overlay = _createNode('div')
			settings_container.id = 's-cnt'
			settings_container_valign.id = 'c-vln'
			settings_container_inner.id = 'c-s-in'
			settings.id = 'cs'
			settings_title.id = 's-ttl'
			settings_inner.id = 's-inr'
			settings_header.id = 's-hdr'
			settings_blocks.id = 's-bl'
			settings_close_btn.id = 's-c-bn'
			overlay.id = 'cs-ov'
			settings_close_btn_container.id = 's-c-bnc'
			settings_close_btn.className = 'c-bn'
			settings_close_btn.setAttribute(
				'aria-label',
				conf_params.languages[lang]['settings_modal']['close_btn_label'] || 'Close'
			)
			settings_container.setAttribute('role', 'dialog')
			settings_container.setAttribute('aria-modal', 'true')
			settings_container.setAttribute('aria-hidden', 'true')
			settings_container.setAttribute('aria-labelledby', 's-ttl')
			settings_title.setAttribute('role', 'heading')
			settings_container.style.visibility = overlay.style.visibility = 'hidden'
			overlay.style.opacity = 0
			settings_close_btn_container.appendChild(settings_close_btn)
			_addEvent(
				settings_container_valign,
				'keydown',
				function (evt) {
					evt = evt || window.event
					if (evt.keyCode === 27) {
						_cookieconsent.hideSettings(0)
					}
				},
				true
			)
			_addEvent(settings_close_btn, 'click', function () {
				_cookieconsent.hideSettings(0)
			})
			all_blocks = conf_params.languages[_config.current_lang]['settings_modal']['blocks']
			all_table_headers = conf_params.languages[_config.current_lang]['settings_modal']['cookie_table_headers']
			var n_blocks = all_blocks.length
			settings_title.insertAdjacentHTML(
				'beforeend',
				conf_params.languages[_config.current_lang]['settings_modal']['title']
			)
			for (var i = 0; i < n_blocks; i += 1) {
				var title_data = all_blocks[i]['title'],
					description_data = all_blocks[i]['description'],
					toggle_data = all_blocks[i]['toggle'],
					cookie_table_data = all_blocks[i]['cookie_table'],
					remove_cookie_tables = conf_params['remove_cookie_tables'] === true,
					isExpandable =
						(description_data && 'truthy') || (!remove_cookie_tables && cookie_table_data && 'truthy')
				var block_section = _createNode('div')
				var block_table_container = _createNode('div')
				if (description_data) {
					var block_desc = _createNode('div')
					block_desc.className = 'p'
					block_desc.insertAdjacentHTML('beforeend', description_data)
				}
				var block_title_container = _createNode('div')
				block_title_container.className = 'title'
				block_section.className = 'c-bl'
				block_table_container.className = 'desc'
				if (typeof toggle_data !== 'undefined') {
					var accordion_id = 'c-ac-' + i
					var block_title_btn = isExpandable ? _createNode('button') : _createNode('div')
					var block_switch_label = _createNode('label')
					var block_switch = _createNode('input')
					var block_switch_span = _createNode('span')
					var label_text_span = _createNode('span')
					var block_switch_span_on_icon = _createNode('span')
					var block_switch_span_off_icon = _createNode('span')
					block_title_btn.className = isExpandable ? 'b-tl exp' : 'b-tl'
					block_switch_label.className = 'b-tg'
					block_switch.className = 'c-tgl'
					block_switch_span_on_icon.className = 'on-i'
					block_switch_span_off_icon.className = 'off-i'
					block_switch_span.className = 'c-tg'
					label_text_span.className = 't-lb'
					if (isExpandable) {
						block_title_btn.setAttribute('aria-expanded', 'false')
						block_title_btn.setAttribute('aria-controls', accordion_id)
					}
					block_switch.type = 'checkbox'
					block_switch_span.setAttribute('aria-hidden', 'true')
					var cookie_category = toggle_data.value
					block_switch.value = cookie_category
					label_text_span[innerText] = title_data
					block_title_btn.insertAdjacentHTML('beforeend', title_data)
					block_title_container.appendChild(block_title_btn)
					block_switch_span.appendChild(block_switch_span_on_icon)
					block_switch_span.appendChild(block_switch_span_off_icon)
					if (never_accepted) {
						if (_inArray(saved_cookie_content['level'], cookie_category) > -1) {
							block_switch.checked = true
							toggle_states.push(true)
						} else {
							toggle_states.push(false)
						}
					} else if (toggle_data['enabled']) {
						block_switch.checked = true
						toggle_states.push(true)
					} else {
						toggle_states.push(false)
					}
					toggle_categories.push(cookie_category)
					if (toggle_data['readonly']) {
						block_switch.disabled = true
						_addClass(block_switch_span, 'c-ro')
						toggle_readonly.push(true)
					} else {
						toggle_readonly.push(false)
					}
					_addClass(block_table_container, 'b-acc')
					_addClass(block_title_container, 'b-bn')
					_addClass(block_section, 'b-ex')
					block_table_container.id = accordion_id
					block_table_container.setAttribute('aria-hidden', 'true')
					block_switch_label.appendChild(block_switch)
					block_switch_label.appendChild(block_switch_span)
					block_switch_label.appendChild(label_text_span)
					block_title_container.appendChild(block_switch_label)
					isExpandable &&
						(function (accordion, block_section, btn) {
							_addEvent(
								block_title_btn,
								'click',
								function () {
									if (!_hasClass(block_section, 'act')) {
										_addClass(block_section, 'act')
										btn.setAttribute('aria-expanded', 'true')
										accordion.setAttribute('aria-hidden', 'false')
									} else {
										_removeClass(block_section, 'act')
										btn.setAttribute('aria-expanded', 'false')
										accordion.setAttribute('aria-hidden', 'true')
									}
								},
								false
							)
						})(block_table_container, block_section, block_title_btn)
				} else {
					if (title_data) {
						var block_title = _createNode('div')
						block_title.className = 'b-tl'
						block_title.setAttribute('role', 'heading')
						block_title.setAttribute('aria-level', '3')
						block_title.insertAdjacentHTML('beforeend', title_data)
						block_title_container.appendChild(block_title)
					}
				}
				title_data && block_section.appendChild(block_title_container)
				description_data && block_table_container.appendChild(block_desc)
				if (!remove_cookie_tables && typeof cookie_table_data !== 'undefined') {
					var tr_tmp_fragment = document.createDocumentFragment()
					for (var p = 0; p < all_table_headers.length; p += 1) {
						var th1 = _createNode('th')
						var obj = all_table_headers[p]
						th1.setAttribute('scope', 'col')
						if (obj) {
							var new_column_key = obj && _getKeys(obj)[0]
							th1[innerText] = all_table_headers[p][new_column_key]
							tr_tmp_fragment.appendChild(th1)
						}
					}
					var tr_tmp = _createNode('tr')
					tr_tmp.appendChild(tr_tmp_fragment)
					var thead = _createNode('thead')
					thead.appendChild(tr_tmp)
					var block_table = _createNode('table')
					block_table.appendChild(thead)
					var tbody_fragment = document.createDocumentFragment()
					for (var n = 0; n < cookie_table_data.length; n += 1) {
						var tr = _createNode('tr')
						for (var g = 0; g < all_table_headers.length; g += 1) {
							obj = all_table_headers[g]
							if (obj) {
								new_column_key = _getKeys(obj)[0]
								var td_tmp = _createNode('td')
								td_tmp.insertAdjacentHTML('beforeend', cookie_table_data[n][new_column_key])
								td_tmp.setAttribute('data-column', obj[new_column_key])
								tr.appendChild(td_tmp)
							}
						}
						tbody_fragment.appendChild(tr)
					}
					var tbody = _createNode('tbody')
					tbody.appendChild(tbody_fragment)
					block_table.appendChild(tbody)
					block_table_container.appendChild(block_table)
				}
				if ((toggle_data && title_data) || (!toggle_data && (title_data || description_data))) {
					block_section.appendChild(block_table_container)
					settings_blocks.appendChild(block_section)
				}
			}
			var settings_buttons = _createNode('div')
			var settings_save_btn = _createNode('button')
			var settings_accept_all_btn = _createNode('button')
			settings_buttons.id = 's-bns'
			settings_save_btn.id = 's-sv-bn'
			settings_accept_all_btn.id = 's-all-bn'
			settings_save_btn.className = 'c-bn'
			settings_accept_all_btn.className = 'c-bn'
			settings_save_btn.insertAdjacentHTML(
				'beforeend',
				conf_params.languages[_config.current_lang]['settings_modal']['save_settings_btn']
			)
			settings_accept_all_btn.insertAdjacentHTML(
				'beforeend',
				conf_params.languages[_config.current_lang]['settings_modal']['accept_all_btn']
			)
			settings_buttons.appendChild(settings_accept_all_btn)
			var reject_all_btn_text = conf_params.languages[_config.current_lang]['settings_modal']['reject_all_btn']
			if (reject_all_btn_text) {
				var reject_all_btn = _createNode('button')
				reject_all_btn.id = 's-rall-bn'
				reject_all_btn.className = 'c-bn'
				reject_all_btn.insertAdjacentHTML('beforeend', reject_all_btn_text)
				_addEvent(reject_all_btn, 'click', function () {
					_cookieconsent.hideSettings()
					_cookieconsent.hide()
					_cookieconsent.accept([])
				})
				settings_inner.className = 'bns-t'
				settings_buttons.appendChild(reject_all_btn)
			}
			settings_buttons.appendChild(settings_save_btn)
			_addEvent(settings_save_btn, 'click', function () {
				_cookieconsent.hideSettings()
				_cookieconsent.hide()
				_cookieconsent.accept()
			})
			_addEvent(settings_accept_all_btn, 'click', function () {
				_cookieconsent.hideSettings()
				_cookieconsent.hide()
				_cookieconsent.accept('all')
			})
			settings_header.appendChild(settings_title)
			settings_header.appendChild(settings_close_btn_container)
			settings_inner.appendChild(settings_header)
			settings_inner.appendChild(settings_blocks)
			settings_inner.appendChild(settings_buttons)
			settings_container_inner.appendChild(settings_inner)
			settings.appendChild(settings_container_inner)
			settings_container_valign.appendChild(settings)
			settings_container.appendChild(settings_container_valign)
			all_modals_container.appendChild(settings_container)
			all_modals_container.appendChild(overlay)
			;(root || document.body).appendChild(main_container)
		}
		var _saveCookiePreferences = function (accepted_categories) {
			var category_toggles = document.querySelectorAll('.c-tgl') || []
			var changedSettings = [],
				must_reload = false
			if (category_toggles.length > 0) {
				for (var i = 0; i < category_toggles.length; i += 1) {
					if (_inArray(accepted_categories, toggle_categories[i]) !== -1) {
						category_toggles[i].checked = true
						if (!toggle_states[i]) {
							changedSettings.push(toggle_categories[i])
							toggle_states[i] = true
						}
					} else {
						category_toggles[i].checked = false
						if (toggle_states[i]) {
							changedSettings.push(toggle_categories[i])
							toggle_states[i] = false
						}
					}
				}
				if (_config.autoclear_cookies && cookie_consent_accepted && changedSettings.length > 0) {
					var len = all_blocks.length
					var count = -1
					var all_cookies_array = _getCookie('', 'all')
					var domains = [_config.cookie_domain, '.' + _config.cookie_domain]
					if (_config.cookie_domain.slice(0, 4) === 'www.') {
						var non_www_domain = _config.cookie_domain.substr(4)
						domains.push(non_www_domain)
						domains.push('.' + non_www_domain)
					}
					for (var jk = 0; jk < len; jk += 1) {
						var curr_block = all_blocks[jk]
						if (Object.prototype.hasOwnProperty.call(curr_block, 'toggle')) {
							if (
								!toggle_states[(count += 1)] &&
								Object.prototype.hasOwnProperty.call(curr_block, 'cookie_table') &&
								_inArray(changedSettings, curr_block['toggle']['value']) > -1
							) {
								var curr_cookie_table = curr_block['cookie_table']
								var ckey = _getKeys(all_table_headers[0])[0]
								var clen = curr_cookie_table.length
								if (curr_block['toggle']['reload'] === 'on_disable') {
									must_reload = true
								}
								for (var hk = 0; hk < clen; hk += 1) {
									var curr_row = curr_cookie_table[hk],
										found_cookies = []
									var curr_cookie_name = curr_row[ckey]
									var is_regex = curr_row['is_regex'] || false
									var curr_cookie_domain = curr_row['domain'] || null
									var curr_cookie_path = curr_row['path'] || false
									curr_cookie_domain && (domains = [curr_cookie_domain, '.' + curr_cookie_domain])
									if (is_regex) {
										for (var n = 0; n < all_cookies_array.length; n += 1) {
											if (all_cookies_array[n].match(curr_cookie_name)) {
												found_cookies.push(all_cookies_array[n])
											}
										}
									} else {
										var found_index = _inArray(all_cookies_array, curr_cookie_name)
										if (found_index > -1) {
											found_cookies.push(all_cookies_array[found_index])
										}
									}
									_log(
										"CookieConsent [AUTOCLEAR]: search cookie: '" + curr_cookie_name + "', found:",
										found_cookies
									)
									if (found_cookies.length > 0) {
										_eraseCookies(found_cookies, curr_cookie_path, domains)
										curr_block['toggle']['reload'] === 'on_clear' && (must_reload = true)
									}
								}
							}
						}
					}
				}
			}
			saved_cookie_content = {
				level: accepted_categories,
				revision: _config.revision,
				data: data,
				rfc_cookie: _config.use_rfc_cookie,
			}
			if (!cookie_consent_accepted || changedSettings.length > 0 || !valid_revision) {
				valid_revision = true
				_setCookie(_config.cookie_name, JSON.stringify(saved_cookie_content))
				_manageExistingScripts()
			}
			if (!cookie_consent_accepted) {
				if (typeof onFirstAction === 'function') {
					onFirstAction(_cookieconsent.getUserPreferences(), saved_cookie_content)
				}
				if (typeof onAccept === 'function') {
					onAccept(saved_cookie_content)
				}
				cookie_consent_accepted = true
				return
			}
			if (typeof onChange === 'function' && changedSettings.length > 0) {
				onChange(saved_cookie_content, changedSettings)
			}
			if (must_reload) {
				window.location.reload()
			}
		}
		var _loadCSS = function (css_path, callback) {
			var enable = typeof css_path === 'string' && css_path !== ''
			if (enable && !document.getElementById('cc--style')) {
				var style = _createNode('style')
				style.id = 'cc--style'
				var xhr = new XMLHttpRequest()
				xhr.onreadystatechange = function () {
					if (this.readyState === 4 && this.status === 200) {
						style.setAttribute('type', 'text/css')
						if (style.styleSheet) {
							style.styleSheet.cssText = this.responseText
						} else {
							style.appendChild(document.createTextNode(this.responseText))
						}
						document.getElementsByTagName('head')[0].appendChild(style)
						_log("CookieConsent [AUTOLOAD_CSS]: loaded style = '" + css_path + "'")
						callback()
					}
				}
				xhr.open('GET', css_path)
				xhr.send()
			} else {
				callback()
			}
		}
		var _inArray = function (arr, value) {
			var len = arr.length
			for (var i = 0; i < len; i += 1) {
				if (arr[i] === value) {
					return i
				}
			}
			return -1
		}
		var _log = function (print_msg, optional_param, error) {
			ENABLE_LOGS &&
				(!error
					? console.log(print_msg, optional_param !== undefined ? optional_param : ' ')
					: console.error(print_msg, optional_param || ''))
		}
		var _createNode = function (type) {
			var el = document.createElement(type)
			if (type === 'button') {
				el.setAttribute('type', type)
			}
			return el
		}
		var _resolveCurrentLang = function (languages, requested_language) {
			_log("CookieConsent [LANG]: auto_language strategy is '" + _config.auto_language + "'")
			if (_config.auto_language === 'browser') {
				return _getValidatedLanguage(_getBrowserLang(), languages)
			} else if (_config.auto_language === 'document') {
				return _getValidatedLanguage(document.documentElement.lang, languages)
			} else {
				if (typeof requested_language === 'string') {
					return (_config.current_lang = _getValidatedLanguage(requested_language, languages))
				}
			}
			_log("CookieConsent [LANG]: setting current_lang = '" + _config.current_lang + "'")
			return _config.current_lang
		}
		var _getBrowserLang = function () {
			var browser_lang = navigator.language || navigator.browserLanguage
			browser_lang.length > 2 && (browser_lang = browser_lang[0] + browser_lang[1])
			_log("CookieConsent [LANG]: detected_browser_lang = '" + browser_lang + "'")
			return browser_lang.toLowerCase()
		}
		var _handleFocusTrap = function () {
			var tabbedOutsideDiv = false
			var tabbedInsideModal = false
			_addEvent(document, 'keydown', function (e) {
				e = e || window.event
				if (e.key !== 'Tab') {
					return
				}
				if (current_modal_focusable) {
					if (e.shiftKey) {
						if (document.activeElement === current_modal_focusable[0]) {
							current_modal_focusable[1].focus()
							e.preventDefault()
						}
					} else {
						if (document.activeElement === current_modal_focusable[1]) {
							current_modal_focusable[0].focus()
							e.preventDefault()
						}
					}
					if (!tabbedInsideModal && !clicked_inside_modal) {
						tabbedInsideModal = true
						!tabbedOutsideDiv && e.preventDefault()
						if (e.shiftKey) {
							if (current_modal_focusable[3]) {
								if (!current_modal_focusable[2]) {
									current_modal_focusable[0].focus()
								} else {
									current_modal_focusable[2].focus()
								}
							} else {
								current_modal_focusable[1].focus()
							}
						} else {
							if (current_modal_focusable[3]) {
								current_modal_focusable[3].focus()
							} else {
								current_modal_focusable[0].focus()
							}
						}
					}
				}
				!tabbedInsideModal && (tabbedOutsideDiv = true)
			})
			if (document.contains) {
				_addEvent(
					main_container,
					'click',
					function (e) {
						e = e || window.event
						if (settings_modal_visible) {
							if (!settings_inner.contains(e.target)) {
								_cookieconsent.hideSettings(0)
								clicked_inside_modal = false
							} else {
								clicked_inside_modal = true
							}
						} else if (consent_modal_visible) {
							if (consent_modal.contains(e.target)) {
								clicked_inside_modal = true
							}
						}
					},
					true
				)
			}
		}
		var _guiManager = function (gui_options, only_consent_modal) {
			if (typeof gui_options !== 'object') {
				return
			}
			var consent_modal_options = gui_options['consent_modal']
			var settings_modal_options = gui_options['settings_modal']
			function _setLayout(
				modal,
				allowed_layouts,
				allowed_positions,
				allowed_transitions,
				layout,
				position,
				transition
			) {
				position = (position && position.split(' ')) || []
				if (_inArray(allowed_layouts, layout) > -1) {
					_addClass(modal, layout)
					if (
						!(layout === 'bar' && position[0] === 'middle') &&
						_inArray(allowed_positions, position[0]) > -1
					) {
						for (var i = 0; i < position.length; i += 1) {
							_addClass(modal, position[i])
						}
					}
				}
				_inArray(allowed_transitions, transition) > -1 && _addClass(modal, transition)
			}
			if (consent_modal_exists && consent_modal_options) {
				_setLayout(
					consent_modal,
					['box', 'bar', 'cloud'],
					['top', 'middle', 'bottom'],
					['zoom', 'slide'],
					consent_modal_options['layout'],
					consent_modal_options['position'],
					consent_modal_options['transition']
				)
			}
			if (!only_consent_modal && settings_modal_options) {
				_setLayout(
					settings_container,
					['bar'],
					['left', 'right'],
					['zoom', 'slide'],
					settings_modal_options['layout'],
					settings_modal_options['position'],
					settings_modal_options['transition']
				)
			}
		}
		_cookieconsent.allowedCategory = function (cookie_category) {
			return (
				_inArray(
					JSON.parse(_getCookie(_config.cookie_name, 'one', true) || '{}')['level'] || [],
					cookie_category
				) > -1
			)
		}
		_cookieconsent.run = function (conf_params) {
			if (!document.getElementById('cc_div')) {
				_setConfig(conf_params)
				if (is_bot) {
					return
				}
				saved_cookie_content = JSON.parse(_getCookie(_config.cookie_name, 'one', true) || '{}')
				cookie_consent_accepted = saved_cookie_content['level'] !== undefined
				data = saved_cookie_content['data'] !== undefined ? saved_cookie_content['data'] : null
				valid_revision =
					typeof conf_params['revision'] === 'number'
						? cookie_consent_accepted
							? conf_params['revision'] > -1
								? saved_cookie_content['revision'] === _config.revision
								: true
							: true
						: true
				consent_modal_exists = !cookie_consent_accepted || !valid_revision
				_createCookieConsentHTML(!consent_modal_exists, conf_params)
				_loadCSS(conf_params['theme_css'], function () {
					_getModalFocusableData()
					_guiManager(conf_params['gui_options'])
					_addCookieSettingsButtonListener()
					if (_config.autorun && consent_modal_exists) {
						_cookieconsent.show(conf_params['delay'] || 0)
					}
					setTimeout(function () {
						_addClass(main_container, 'c--anim')
					}, 30)
					setTimeout(function () {
						_handleFocusTrap()
					}, 100)
				})
				if (cookie_consent_accepted && valid_revision) {
					var rfc_prop_exists = typeof saved_cookie_content['rfc_cookie'] === 'boolean'
					if (
						!rfc_prop_exists ||
						(rfc_prop_exists && saved_cookie_content['rfc_cookie'] !== _config.use_rfc_cookie)
					) {
						saved_cookie_content['rfc_cookie'] = _config.use_rfc_cookie
						_setCookie(_config.cookie_name, JSON.stringify(saved_cookie_content))
					}
					_manageExistingScripts()
					if (typeof conf_params['onAccept'] === 'function') {
						conf_params['onAccept'](saved_cookie_content)
					}
				}
			} else {
				_log('CookieConsent [NOTICE]: cookie consent already attached to body!')
			}
		}
		_cookieconsent.showSettings = function (delay) {
			setTimeout(
				function () {
					_addClass(html_dom, 'show--settings')
					settings_container.setAttribute('aria-hidden', 'false')
					settings_modal_visible = true
					setTimeout(function () {
						if (!consent_modal_visible) {
							last_elem_before_modal = document.activeElement
						} else {
							last_consent_modal_btn_focus = document.activeElement
						}
						if (settings_modal_focusable.length === 0) {
							return
						}
						if (settings_modal_focusable[3]) {
							settings_modal_focusable[3].focus()
						} else {
							settings_modal_focusable[0].focus()
						}
						current_modal_focusable = settings_modal_focusable
					}, 200)
					_log('CookieConsent [SETTINGS]: show settings_modal')
				},
				delay > 0 ? delay : 0
			)
		}
		var _manageExistingScripts = function () {
			if (!_config.page_scripts) {
				return
			}
			var scripts = document.querySelectorAll('script[' + _config.script_selector + ']')
			var sequential_enabled = _config.page_scripts_order
			var accepted_categories = saved_cookie_content['level'] || []
			_log('CookieConsent [SCRIPT_MANAGER]: sequential loading:', sequential_enabled)
			var _loadScripts = function (scripts, index) {
				if (index < scripts.length) {
					var curr_script = scripts[index]
					var curr_script_category = curr_script.getAttribute(_config.script_selector)
					if (_inArray(accepted_categories, curr_script_category) > -1) {
						curr_script.type = 'text/javascript'
						curr_script.removeAttribute(_config.script_selector)
						var src = curr_script.getAttribute('data-src')
						var fresh_script = _createNode('script')
						fresh_script.textContent = curr_script.innerHTML
						;(function (destination, source) {
							var attr,
								attributes = source.attributes
							var len = attributes.length
							for (var i = 0; i < len; i += 1) {
								attr = attributes[i]
								destination.setAttribute(attr.nodeName, attr.nodeValue)
							}
						})(fresh_script, curr_script)
						src ? (fresh_script.src = src) : (src = curr_script.src)
						if (src) {
							if (sequential_enabled) {
								if (fresh_script.readyState) {
									fresh_script.onreadystatechange = function () {
										if (
											fresh_script.readyState === 'loaded' ||
											fresh_script.readyState === 'complete'
										) {
											fresh_script.onreadystatechange = null
											_loadScripts(scripts, (index += 1))
										}
									}
								} else {
									fresh_script.onload = function () {
										fresh_script.onload = null
										_loadScripts(scripts, (index += 1))
									}
								}
							} else {
								src = false
							}
						}
						curr_script.parentNode.replaceChild(fresh_script, curr_script)
						if (src) {
							return
						}
					}
					_loadScripts(scripts, (index += 1))
				}
			}
			_loadScripts(scripts, 0)
		}
		var _setCookieData = function (new_data, mode) {
			var set = false
			if (mode === 'update') {
				data = _cookieconsent.get('data')
				var same_type = typeof data === typeof new_data
				if (same_type && typeof data === 'object') {
					!data && (data = {})
					for (var prop in new_data) {
						if (data[prop] !== new_data[prop]) {
							data[prop] = new_data[prop]
							set = true
						}
					}
				} else if ((same_type || !data) && data !== new_data) {
					data = new_data
					set = true
				}
			} else {
				data = new_data
				set = true
			}
			if (set) {
				saved_cookie_content['data'] = data
				_setCookie(_config.cookie_name, JSON.stringify(saved_cookie_content))
			}
			return set
		}
		var _setRevision = function (new_revision, prompt_consent, message) {
			if (
				main_container &&
				typeof new_revision === 'number' &&
				saved_cookie_content['revision'] !== new_revision
			) {
				revision_enabled = true
				revision_message = message
				valid_revision = false
				_config.revision = new_revision
				if (prompt_consent === true) {
					_createConsentModal(_conf_params)
					_guiManager(_conf_params['gui_options'], true)
					_getModalFocusableData()
					_cookieconsent.show()
				} else {
					_cookieconsent.accept()
				}
				return true
			}
			return false
		}
		_cookieconsent.set = function (field, data) {
			switch (field) {
				case 'data':
					return _setCookieData(data['value'], data['mode'])
				case 'revision':
					return _setRevision(data['value'], data['prompt_consent'], data['message'])
				default:
					return false
			}
		}
		_cookieconsent.get = function (field, cookie_name) {
			var cookie = JSON.parse(_getCookie(cookie_name || _config.cookie_name, 'one', true) || '{}')
			return cookie[field]
		}
		_cookieconsent.getConfig = function (field) {
			return _config[field]
		}
		_cookieconsent.getUserPreferences = function () {
			accepted_categories = saved_cookie_content['level'] || []
			var necessary_categories_count = toggle_readonly.filter(function (readonly) {
				return readonly === true
			}).length
			rejected_categories = toggle_categories.filter(function (category) {
				return _inArray(accepted_categories, category) === -1
			})
			if (accepted_categories.length === toggle_categories.length) {
				accept_type = 'all'
			} else if (rejected_categories.length >= 0 && accepted_categories.length === necessary_categories_count) {
				accept_type = 'necessary'
			} else {
				accept_type = 'custom'
			}
			return {
				accept_type: accept_type,
				accepted_categories: accepted_categories,
				rejected_categories: rejected_categories,
			}
		}
		_cookieconsent.loadScript = function (src, callback, attrs) {
			var function_defined = typeof callback === 'function'
			if (!document.querySelector('script[src="' + src + '"]')) {
				var script = _createNode('script')
				if (attrs && attrs.length > 0) {
					for (var i = 0; i < attrs.length; i += 1) {
						attrs[i] && script.setAttribute(attrs[i]['name'], attrs[i]['value'])
					}
				}
				if (function_defined) {
					if (script.readyState) {
						script.onreadystatechange = function () {
							if (script.readyState === 'loaded' || script.readyState === 'complete') {
								script.onreadystatechange = null
								callback()
							}
						}
					} else {
						script.onload = callback
					}
				}
				script.src = src
				;(document.head ? document.head : document.getElementsByTagName('head')[0]).appendChild(script)
			} else {
				function_defined && callback()
			}
		}
		_cookieconsent.updateScripts = function () {
			_manageExistingScripts()
		}
		_cookieconsent.show = function (delay) {
			if (consent_modal_exists) {
				setTimeout(
					function () {
						_addClass(html_dom, 'show--consent')
						consent_modal.setAttribute('aria-hidden', 'false')
						consent_modal_visible = true
						setTimeout(function () {
							last_elem_before_modal = document.activeElement
							current_modal_focusable = consent_modal_focusable
						}, 200)
						_log('CookieConsent [MODAL]: show consent_modal')
					},
					delay > 0 ? delay : 0
				)
			}
		}
		_cookieconsent.hide = function () {
			if (consent_modal_exists) {
				_removeClass(html_dom, 'show--consent')
				consent_modal.setAttribute('aria-hidden', 'true')
				consent_modal_visible = false
				setTimeout(function () {
					last_elem_before_modal.focus()
					current_modal_focusable = null
				}, 200)
				_log('CookieConsent [MODAL]: hide')
			}
		}
		_cookieconsent.hideSettings = function () {
			_removeClass(html_dom, 'show--settings')
			settings_modal_visible = false
			settings_container.setAttribute('aria-hidden', 'true')
			setTimeout(function () {
				if (consent_modal_visible) {
					last_consent_modal_btn_focus && last_consent_modal_btn_focus.focus()
					current_modal_focusable = consent_modal_focusable
				} else {
					last_elem_before_modal.focus()
					current_modal_focusable = null
				}
				clicked_inside_modal = false
			}, 200)
			_log('CookieConsent [SETTINGS]: hide settings_modal')
		}
		_cookieconsent.accept = function (_categories, _exclusions) {
			var categories = _categories || undefined
			var exclusions = _exclusions || []
			var to_accept = []
			var _getCurrentPreferences = function () {
				var toggles = document.querySelectorAll('.c-tgl') || []
				var states = []
				for (var i = 0; i < toggles.length; i += 1) {
					if (toggles[i].checked) {
						states.push(toggles[i].value)
					}
				}
				return states
			}
			if (!categories) {
				to_accept = _getCurrentPreferences()
			} else {
				if (typeof categories === 'object' && typeof categories.length === 'number') {
					for (var i = 0; i < categories.length; i += 1) {
						if (_inArray(toggle_categories, categories[i]) !== -1) {
							to_accept.push(categories[i])
						}
					}
				} else if (typeof categories === 'string') {
					if (categories === 'all') {
						to_accept = toggle_categories.slice()
					} else {
						if (_inArray(toggle_categories, categories) !== -1) {
							to_accept.push(categories)
						}
					}
				}
			}
			if (exclusions.length >= 1) {
				for (i = 0; i < exclusions.length; i += 1) {
					to_accept = to_accept.filter(function (item) {
						return item !== exclusions[i]
					})
				}
			}
			for (i = 0; i < toggle_categories.length; i += 1) {
				if (toggle_readonly[i] === true && _inArray(to_accept, toggle_categories[i]) === -1) {
					to_accept.push(toggle_categories[i])
				}
			}
			_saveCookiePreferences(to_accept)
		}
		_cookieconsent.eraseCookies = function (_cookies, _path, _domain) {
			var cookies = []
			var domains = _domain ? [_domain, '.' + _domain] : [_config.cookie_domain, '.' + _config.cookie_domain]
			if (typeof _cookies === 'object' && _cookies.length > 0) {
				for (var i = 0; i < _cookies.length; i += 1) {
					this.validCookie(_cookies[i]) && cookies.push(_cookies[i])
				}
			} else {
				this.validCookie(_cookies) && cookies.push(_cookies)
			}
			_eraseCookies(cookies, _path, domains)
		}
		var _setCookie = function (name, value) {
			var cookie_expiration = _config.cookie_expiration
			if (typeof _config.cookie_necessary_only_expiration === 'number' && accept_type === 'necessary') {
				cookie_expiration = _config.cookie_necessary_only_expiration
			}
			value = _config.use_rfc_cookie ? encodeURIComponent(value) : value
			var date = new Date()
			date.setTime(date.getTime() + 1000 * (cookie_expiration * 24 * 60 * 60))
			var expires = '; expires=' + date.toUTCString()
			var cookieStr = name + '=' + (value || '') + expires + '; Path=' + _config.cookie_path + ';'
			cookieStr += ' SameSite=' + _config.cookie_same_site + ';'
			if (window.location.hostname.indexOf('.') > -1) {
				cookieStr += ' Domain=' + _config.cookie_domain + ';'
			}
			if (window.location.protocol === 'https:') {
				cookieStr += ' Secure;'
			}
			document.cookie = cookieStr
			_log(
				'CookieConsent [SET_COOKIE]: cookie ' +
					name +
					"='" +
					value +
					"' was set! Expires after " +
					cookie_expiration +
					' days'
			)
		}
		var _getCookie = function (name, filter, get_value) {
			var found
			if (filter === 'one') {
				found = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
				found = found ? (get_value ? found.pop() : name) : ''
				if (found && name === _config.cookie_name) {
					try {
						found = JSON.parse(found)
					} catch (e) {
						try {
							found = JSON.parse(decodeURIComponent(found))
						} catch (e) {
							found = {}
						}
					}
					found = JSON.stringify(found)
				}
			} else if (filter === 'all') {
				var cookies = document.cookie.split(/;\s*/)
				found = []
				for (var i = 0; i < cookies.length; i += 1) {
					found.push(cookies[i].split('=')[0])
				}
			}
			return found
		}
		var _eraseCookies = function (cookies, custom_path, domains) {
			var path = custom_path ? custom_path : '/'
			var expires = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
			for (var i = 0; i < cookies.length; i += 1) {
				for (var j = 0; j < domains.length; j += 1) {
					document.cookie =
						cookies[i] +
						'=; path=' +
						path +
						(domains[j].indexOf('.') > -1 ? '; domain=' + domains[j] : '') +
						'; ' +
						expires
				}
				_log(
					"CookieConsent [AUTOCLEAR]: deleting cookie: '" + cookies[i] + "' path: '" + path + "' domain:",
					domains
				)
			}
		}
		_cookieconsent.validCookie = function (cookie_name) {
			return _getCookie(cookie_name, 'one', true) !== ''
		}
		var _addEvent = function (elem, event, fn, _passive) {
			var passive = _passive === true
			if (elem.addEventListener) {
				passive ? elem.addEventListener(event, fn, { passive: true }) : elem.addEventListener(event, fn, false)
			} else {
				elem.attachEvent('on' + event, fn)
			}
		}
		var _getKeys = function (obj) {
			if (typeof obj === 'object') {
				var keys = [],
					i = 0

				for (keys[i++] in obj) {
					/* empty */
				}
				return keys
			}
		}
		var _addClass = function (elem, classname) {
			if (elem.classList) {
				elem.classList.add(classname)
			} else {
				if (!_hasClass(elem, classname)) {
					elem.className += ' ' + classname
				}
			}
		}
		var _removeClass = function (el, className) {
			el.classList
				? el.classList.remove(className)
				: (el.className = el.className.replace(new RegExp('(\\s|^)' + className + '(\\s|$)'), ' '))
		}
		var _hasClass = function (el, className) {
			if (el.classList) {
				return el.classList.contains(className)
			}
			return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
		}
		return _cookieconsent
	}
	var init = 'initCookieConsent'
	if (typeof window[init] !== 'function') {
		window[init] = CookieConsent
	}
})()

// obtain plugin
// var cc = initCookieConsent()
