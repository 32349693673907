// obtain plugin
var cc = initCookieConsent();
var $domain = "asafebezpecnostnezabrany.sk"

// run plugin with your configuration
cc.run({
    current_lang: 'sk',
    autoclear_cookies: true,                   // default: false
    theme_css: '/css/cookieconsent.css',  // 🚨 replace with a valid path
    page_scripts: true,                        // default: false
    auto_language: 'document',

    gui_options: {
        consent_modal: {
            layout: 'bar',                    // box,cloud,bar
            position: 'bottom right',          // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        }
    },

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {

        if(cc.allowedCategory('analytics')){
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });
        }

        if(cc.allowedCategory('marketing')){
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
            });
        }
    },

    onChange: function (cookie, changed_preferences) {
        let analytics = '';
        let marketing = '';

        if (changed_preferences.indexOf('analytics') > -1) {
            // If analytics category is disabled ...
            if (!cc.allowedCategory('analytics')) {
                cc.eraseCookies(['analytics']);
                document.cookie = "_ga=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "_gid=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "_dc_gtm_UA-29164187-1=; Max-Age=0; path=/; domain=" + $domain;
            }
        }

        if (changed_preferences.indexOf('marketing') > -1) {
            // If analytics category is disabled ...
            if (!cc.allowedCategory('marketing')) {
                cc.eraseCookies(['marketing']);
                document.cookie = "_fbp=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-3PAPISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-1PAPISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SAPISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "APISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-3PSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-1PSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SIDCC=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "APISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-3PAPISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SAPISID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "HSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-1PSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-3PSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "HSID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "__Secure-3PSIDCC=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "NID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "DV=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "NID=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "CONSENT=; Max-Age=0; path=/; domain=" + $domain;
                document.cookie = "SEARCH_SAMESITE=; Max-Age=0; path=/; domain=" + $domain;
            }
        }

        if(cc.allowedCategory('analytics')){
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });
        }
        else{
            gtag('consent', 'update', {
                'analytics_storage': 'denied'
            });
        }

        if(cc.allowedCategory('marketing')){
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
            });
        }
        else{
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
            });
        }
    },

    languages: {
        'sk': {
            consent_modal: {
                layout: 'bar',
                position: 'bottom center',
                transition: 'slide',
                description: 'Tieto webové stránky využívajú cookies na ukladanie dát potrebných pre zlepšovanie kvality prehliadania. Jednotlivé cookies môžete prijať alebo odmietnuť na základe nastavení, ktoré nájdete v sekcii Nastavenia cookies. Kým cookies v tejto sekcii nenastavíte, nekliknete na tlačidlo „Akceptovať“ alebo ich odmietnete, nebudú sa ukladať, okrem cookies potrebných pre technický chod stránky. Zoznam cookies a ich nastavenie nájdete nižšie.',
                secondary_btn: {
                    text: 'Odmietnuť všetky',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                },
                primary_btn: {
                    text: 'Akceptovať všetky',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }

            },
            settings_modal: {
                title: 'Nastavenia cookies',
                save_settings_btn: 'Uložiť nastavenia',
                accept_all_btn: 'Akceptovať všetko',
                close_btn_label: 'Zatvoriť',
                cookie_table_headers: [
                    {col1: 'Názov'},
                    {col2: 'Doména'},
                    {col3: 'Expirácia'},
                ],
                blocks: [
                    {
                        //  title: 'Cookie usage 📢',
                        //  description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Funkčné cookies',
                        description: 'Sú základné súbory cookie, ktoré zabezpečujú správne fungovanie webovej stránky.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'asafebezpecnostnezabrany_session',
                                col2:  $domain,
                                col3: '2 týždne',
                                is_regex: true
                            },
                            {
                                col1: 'XSRF-TOKEN   ',
                                col2:  $domain,
                                col3: '2 týždne',
                                is_regex: true
                            },
                            {
                                col1: 'GRECAPTCHA',
                                col2:  'www.google.com',
                                col3: '6 mesiacov',
                                is_regex: true
                            },
                            {
                                col1: 'NID',
                                col2: 'google.sk',
                                col3: '6 mesiacov',
                            },
                            {
                                col1: 'SOCS',
                                col2: 'google.sk',
                                col3: '1 mesiac',
                            },
                            {
                                col1: 'DV',
                                col2: 'google.com',
                                col3: '6 mesiacov',
                            },
                            {
                                col1: 'AEC',
                                col2: 'google.com',
                                col3: '6 mesiacov',
                            },
                        ]
                    }, {
                        title: 'Analytické cookies',
                        description: 'Analytické cookies anonymne zbierajú štatistiky a meria pohyb na webových stránkach.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 roky',
                                //  col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 deň',
                                //col4: 'description ...',
                            },
                        ]
                    }, {
                        title: 'Reklamné cookies',
                        description: 'Používajú sa pre zobrazovaní relevantných a personalizovaných reklamných ponúk na sociálnych sieťach.',
                        toggle: {
                            value: 'marketing',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_fbp',
                                col2: 'facebook.com',
                                col3: '2 roky',
                            },
                            {
                                col1: '^__Secure',
                                col2: 'google.com',
                                col3: '3 roky',
                                is_regex: true
                            },
                            {
                                col1: 'SAPISID',
                                col2: 'google.com',
                                col3: '3 roky',
                            },
                            {
                                col1: 'APISID',
                                col2: 'google.sk',
                                col3: '3 roky',
                            },
                            {
                                col1: 'SSID',
                                col2: 'google.com',
                                col3: '3 roky',
                            },
                            {
                                col1: 'HSID',
                                col2: 'google.sk',
                                col3: '2 roky',
                            },
                            {
                                col1: 'SID',
                                col2: 'google.com',
                                col3: '2 roky',
                            },
                            {
                                col1: 'SIDCC',
                                col2: 'google.com',
                                col3: '1 rok',
                            },
                            {
                                col1: 'IDE',
                                col2: 'google.com',
                                col3: '2 roky',
                            },
                            {
                                col1: '1p_jar',
                                col2: 'google.com',
                                col3: '1 mesiac',
                            },
                        ]
                    }, {
                        //  title: 'Viac informácii',
                        description: 'Tieto nastavenia môžete kedykoľvek v budúcnosti upraviť',
                    }
                ]
            }
        },
        'en': {
            consent_modal: {
                layout: 'bar',
                position: 'bottom center',
                transition: 'slide',
                description: 'This website uses cookies to store data needed for quality improvement of the website. Cookies may be accepted or declined based od settings. While these cookies are not set by you, they won\'t be applied, except the functional cookies. List of cookies may be found below.',
                secondary_btn: {
                    text: 'Deny all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                },
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }

            },
            settings_modal: {
                title: 'Cookie settings',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                ],
                blocks: [
                    {
                        //  title: 'Cookie usage 📢',
                        //  description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Functional cookies',
                        description: 'Are basic cookies needed for correct functioning of this website.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'asafebezpecnostnezabrany_session',
                                col2:  $domain,
                                col3: '2 weeks',
                                is_regex: true
                            },
                            {
                                col1: 'XSRF-TOKEN   ',
                                col2:  $domain,
                                col3: '2 weeks',
                                is_regex: true
                            },
                            {
                                col1: 'GRECAPTCHA',
                                col2:  'www.google.com',
                                col3: '6 months',
                                is_regex: true
                            },
                            {
                                col1: 'NID',
                                col2: 'google.sk',
                                col3: '6 months',
                            },
                            {
                                col1: 'SOCS',
                                col2: 'google.sk',
                                col3: '1 month',
                            },
                            {
                                col1: 'DV',
                                col2: 'google.com',
                                col3: '6 months',
                            },
                            {
                                col1: 'AEC',
                                col2: 'google.com',
                                col3: '6 months',
                            },
                        ]
                    }, {
                        title: 'Analytical cookies',
                        description: 'Analytical cookies are collecting stats and capturing movement on this website.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                //  col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                //col4: 'description ...',
                            },
                        ]
                    }, {
                        title: 'Marketing cookies',
                        description: 'Are used for displaying relevant and personalized offers on social networks.',
                        toggle: {
                            value: 'marketing',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_fbp',
                                col2: 'facebook.com',
                                col3: '2 years',
                            },
                            {
                                col1: '^__Secure',
                                col2: 'google.com',
                                col3: '3 years',
                                is_regex: true
                            },
                            {
                                col1: 'SAPISID',
                                col2: 'google.com',
                                col3: '3 years',
                            },
                            {
                                col1: 'APISID',
                                col2: 'google.com',
                                col3: '3 years',
                            },
                            {
                                col1: 'SSID',
                                col2: 'google.com',
                                col3: '3 years',
                            },
                            {
                                col1: 'HSID',
                                col2: 'google.sk',
                                col3: '2 years',
                            },
                            {
                                col1: 'SID',
                                col2: 'google.sk',
                                col3: '2 years',
                            },
                            {
                                col1: 'SIDCC',
                                col2: 'google.sk',
                                col3: '1 year',
                            },
                            {
                                col1: 'IDE',
                                col2: 'google.com',
                                col3: '2 years',
                            },
                            {
                                col1: '1p_jar',
                                col2: 'google.com',
                                col3: '1 month',
                            },
                        ]
                    }, {
                        //  title: 'Viac informácii',
                        description: 'These settiings may be changed anytime in the future',
                    }
                ]
            }
        },
        'cz': {
            consent_modal: {
                layout: 'bar',
                position: 'bottom center',
                transition: 'slide',
                description: 'Tyto webové stránky využívají cookies k ukládání dat potřebných pro zlepšování kvality procházení. Jednotlivé cookies můžete přijmout nebo odmítnout na základě nastavení, která naleznete v sekci Nastavení cookies. Dokud cookies v této sekci nenastavíte, nekliknete na tlačítko „Akceptovat“ nebo je odmítnete, nebudou se ukládat, kromě cookies potřebných pro technický chod stránky. Seznam cookies a jejich nastavení naleznete níže.',
                secondary_btn: {
                    text: 'Odmítnout všechny',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                },
                primary_btn: {
                    text: 'Akceptovať všechny',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }

            },
            settings_modal: {
                title: 'Nastavení cookies',
                save_settings_btn: 'Uložiť nastavení',
                accept_all_btn: 'Akceptovať všechno',
                close_btn_label: 'Zavŕít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Doména'},
                    {col3: 'Expirace'},
                ],
                blocks: [
                    {
                        //  title: 'Cookie usage 📢',
                        //  description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Funkční cookies',
                        description: 'Jsou základní soubory cookie, které zajišťují správné fungování webové stránky.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'asafebezpecnostnezabrany_session',       // match all cookies starting with "_ga"
                                col2:  $domain,
                                col3: '2 týdny',
                                is_regex: true
                            },
                            {
                                col1: 'XSRF-TOKEN   ',       // match all cookies starting with "_ga"
                                col2:  $domain,
                                col3: '2 týdny',
                                is_regex: true
                            },
                            {
                                col1: 'GRECAPTCHA',
                                col2:  'www.google.com',
                                col3: '6 měsícu',
                                is_regex: true
                            },
                            {
                                col1: 'NID',
                                col2: 'google.sk',
                                col3: '6 měsícu',
                            },
                            {
                                col1: 'SOCS',
                                col2: 'google.sk',
                                col3: '1 měsíc',
                            },
                            {
                                col1: 'DV',
                                col2: 'google.com',
                                col3: '6 měsícu',
                            },
                            {
                                col1: 'AEC',
                                col2: 'google.com',
                                col3: '6 měsícu',
                            },
                        ]
                    }, {
                        title: 'Analytické cookies',
                        description: 'Analytické cookies anonymně sbírají statistiky a měří pohyb na webových stránkách.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 roky',
                                //  col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 den',
                                //col4: 'description ...',
                            },
                        ]
                    },
                    {
                        title: 'Reklamní cookies',
                        description: 'Používají se na zobrazování relevantních a personalizovaných reklamních ponuk na sociálních sítích.',
                        toggle: {
                            value: 'marketing',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_fbp',
                                col2: 'facebook.com',
                                col3: '2 roky',
                            },
                            {
                                col1: '^__Secure',
                                col2: 'google.com',
                                col3: '3 roky',
                                is_regex: true
                            },
                            {
                                col1: 'SAPISID',
                                col2: 'google.com',
                                col3: '3 roky',
                            },
                            {
                                col1: 'APISID',
                                col2: 'google.sk',
                                col3: '3 roky',
                            },
                            {
                                col1: 'SSID',
                                col2: 'google.com',
                                col3: '3 roky',
                            },
                            {
                                col1: 'HSID',
                                col2: 'google.sk',
                                col3: '2 roky',
                            },
                            {
                                col1: 'SID',
                                col2: 'google.com',
                                col3: '2 roky',
                            },
                            {
                                col1: 'SIDCC',
                                col2: 'google.com',
                                col3: '1 rok',
                            },
                            {
                                col1: 'IDE',
                                col2: 'google.com',
                                col3: '2 roky',
                            },
                            {
                                col1: '1p_jar',
                                col2: 'google.com',
                                col3: '1 mesiac',
                            },
                        ]
                    },
                    {
                        //  title: 'Viac informácii',
                        description: 'Tato nastavení můžete kdykoli v budoucnu upravit',
                    }
                ]
            }
        },
    }
});

if(document.getElementById('c-bns'))
{
    let lang = document.documentElement.lang
    let btnWrapper = document.getElementById('c-bns');
    let newButton = document.createElement('button');
    newButton.type = 'button';
    newButton.className = 'c-bn c-bn-custom';
    // newButton.style = 'background: none;text-decoration: underline;';
    newButton.setAttribute('data-cc', 'c-settings');
    newButton.setAttribute('id', 'c-s-bn');
    if (lang === 'sk') {
        newButton.innerHTML = 'Nastavenia cookies';
    }
    else if (lang === 'en') {
        newButton.innerHTML = 'Cookie settings';
    }
    else if (lang === 'cz') {
        newButton.innerHTML = 'Nastavení cookies';
    }
    else {
        newButton.innerHTML = 'Nastavenia cookies';
    }
    btnWrapper.appendChild(newButton);
}
